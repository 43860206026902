<template>
  <div style="width: 100%; overflow-x: hidden" id="chart">
    <TitleWithBackButton
      title="Riepilogo Commerciale Operatore"
      back-route="Analytics"
      icon="mdi-graphql"
    ></TitleWithBackButton>
    <div class="d-flex align-center ml-3 mt-3 flex-wrap">
      <RangeDateSelector
        :start-date.sync="startDate"
        :end-date.sync="endDate"
        :loading="loading"
        :disabled="loading"
        :max-width="!!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
            ? 'fit-content' : this.$vuetify.breakpoint.md ? '70vw' : '50vw'"
      ></RangeDateSelector>
      <v-spacer></v-spacer>
      <v-btn
        class="mr-2 ml-2"
        text
        @click="calculateOperatorTotal"
        :loading="loading"
        :disabled="!startDate || !endDate"
      >Calcola</v-btn>
      <v-btn
        class="btn-export"
        @click="exportToExcel"
        :loading="loading"
      ><v-icon> mdi-microsoft-excel </v-icon></v-btn>
      <v-btn
        class="mr-2 ml-2"
        text
        @click="print"
        :loading="loading"
        :disabled="!startDate || !endDate"
      ><v-icon> mdi-printer </v-icon></v-btn>
      <v-btn 
        icon 
        large 
        :disabled="loading" 
        :color="!!isB ? 'red' : ''"
        @click="() => { dialogBarcode = !dialogBarcode }">
          <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
    </div>
    <div class="py-10 px-1">
      <div class="pb-10" id="operators-table-container">
        <TypeDataTable
          headersColor="rgba(187, 179, 233, 1);"
          style="background-color: rgba(138, 119, 226,0.2);"
          item-key="name"
          :headers="totalTableHeaders" 
          :items="totalsData" 
          :show-select="false" 
          :select-on-row-click="false"
          :show-actions="false" 
          :disablePagination="true" 
        >
          <template v-slot:custom-name="{ item }">
            <div class="d-flex jusitfy-center align-center">
              <v-btn
                v-if="!!item.id"
                class="mr-2"
                text
                small
                @click="handleClick(item)"
                :disabled="(!!operator && operator.id == item.id) || loading"
                fab
              >
                <v-icon>{{ !!operator && operator.id == item.id ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
              </v-btn>
              <v-btn v-else disabled text small fab class="mr-2">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              {{ item.name }}
            </div>
          </template>
          <template v-slot:custom-customTypePrice="{ item, value }">
            <div class="d-flex justify-center align-center">
              {{ !!value ? Number(value).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) : (0).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }} 
            </div>
          </template>
          <template v-slot:custom-customTypeText="{ item, value }">
            <div class="d-flex justify-center align-center">
              {{ value }}
            </div>
          </template>
        </TypeDataTable>
      </div>
      <v-container fluid class="mt-5">
        <div v-if="loading" class="text-center mt-2">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <div v-else-if="!this.results" class="text-center font-weight-light mt-2">
          Nessun risultato
        </div>
        <div v-else>
          <v-row class="d-flex justify-center mb-2">
            <v-chip color="primary" style="font-size: 1.3rem;">{{ operator.name }}</v-chip>
          </v-row>
          <v-row class="pb-5 d-flex justify-center">
            <v-col class="d-flex flex-column align-center">
              <div>
                ORE DI PRODUZIONE
              </div>
              <v-row class="d-flex justify-center pt-2">
                <PieChart
                  :chart-data="hoursChartData"
                  :options="hoursChartOptions"
                >
                </PieChart>
              </v-row>
              <TypeDataTable
                style="background-color: transparent;"
                headersColor="transparent"
                :headers="hoursTableHeaders"
                :items="hoursTableRows"
                :show-select="false"
                :select-on-row-click="false"
                :show-actions="false"
                :disablePagination="true"
              >
              </TypeDataTable>
            </v-col>
            <v-col class="d-flex flex-column align-center">
              <div>
                ASSENZE
              </div>
              <v-row class="d-flex justify-center pt-2">
                <PieChart
                  :chart-data="presenceChartData"
                  :options="presenceChartOptions"
                >
                </PieChart>
              </v-row>
              <TypeDataTable
                style="background-color: transparent;"
                headersColor="transparent"
                :headers="presenceTableHeaders"
                :items="presenceTableRows"
                :show-select="false"
                :select-on-row-click="false"
                :show-actions="false"
                :disablePagination="true"
              >
              </TypeDataTable>
            </v-col>
            <v-col class="d-flex flex-column align-center">
              <div>
                PUNTUALITA
              </div>
              <v-row class="d-flex justify-center pt-2">
                <PieChart
                  :chart-data="delayChartData"
                  :options="delayChartOptions"
                >
                </PieChart>
              </v-row>
              <TypeDataTable
                style="background-color: transparent;"
                headersColor="transparent"
                :headers="delayTableHeaders"
                :items="delayTableRows"
                :show-select="false"
                :select-on-row-click="false"
                :show-actions="false"
                :disablePagination="true"
              >
              </TypeDataTable>
            </v-col>
            <v-col class="d-flex flex-column align-center">
              <div>
                STRAORDINARIO
              </div>
              <v-row class="d-flex justify-center pt-2">
                <PieChart
                  :chart-data="overtimeChartData"
                  :options="hoursChartOptions"
                ></PieChart>
              </v-row>
              <TypeDataTable
                style="background-color: transparent;"
                headersColor="transparent"
                :headers="overtimeTableHeaders"
                :items="overtimeTableRows"
                :show-select="false"
                :select-on-row-click="false"
                :show-actions="false"
                :disablePagination="true"
              >
              </TypeDataTable>
            </v-col>
          </v-row>
          <v-divider class="my-15"></v-divider>
          <v-row class="pt-5">
            <v-col cols="12">
              <v-row class="d-flex justify-center">     
                <PolarAreaChart
                  :chart-data="operatorTotalChartData"
                  :options="operatorTotalChartOptions"
                ></PolarAreaChart>
              </v-row>
              <v-row class="pt-2 d-flex justify-center">
                <TypeDataTable
                  style="background-color: transparent;"
                  headersColor="transparent"
                  :headers="operatorTotalHeaders"
                  :items="operatorTotalRows"
                  :show-select="false"
                  :select-on-row-click="false"
                  :show-actions="false"
                  :disablePagination="true"
                >
                </TypeDataTable>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col 
              lg="6"
              xl="6"
              md="12" 
              cols="12"
              sm="12"
            >
              <v-row>
                <GroupBasedChart
                  style="width: 100%; height: 100%"
                  type="bar"
                  transparent
                  :colors="['rgba(80,191,225,0.5)', 'rgba(80,191,225,0.5)', 'rgba(80,191,225,0.5)', 'rgba(80,191,225,0.5)', 'rgba(80,191,225,0.5)']"
                  :point-colors="['rgba(80,191,225,1)', 'rgba(80,191,225,1)', 'rgba(80,191,225,1)', 'rgba(80,191,225,1)', 'rgba(80,191,225,1)']"
                  :data="groupedTagServicesChartData"
                  :options="[]"
                  :getYValue="
                    (object) => {
                      return object[`count`];
                    }"
                  :getXValue="
                    (object) => {
                      return object[`name`];
                    }"
                  :x-axes-modifier="xAxes"
                  :tooltip-label-modifier="
                    (tooltipLabel) => {
                      return 'Quantità: ' + tooltipLabel.yLabel;
                    }"
                >
                </GroupBasedChart>
              </v-row>
              <v-row>
                <v-list style="width: 100%" color="transparent">
                  <v-list-item class="pr-9 mr-9" disabled>
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title class="d-flex justify-space-between">
                          <v-col cols="7">
                            Nome
                          </v-col>
                          <v-col cols="2">
                            Totale
                          </v-col>
                          <v-col cols="2">
                            %
                          </v-col>
                          <v-col cols="1">
                            Qtà
                          </v-col>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                  <v-list-group
                    v-for="(category, idx) in groupedTagServices"
                    :key="idx"
                    no-action
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title class="d-flex justify-space-between">
                          <v-col cols="7">
                            {{ (idx + 1) + '° ' + category.name }}
                          </v-col>
                          <v-col cols="2">
                            {{ Number(category.total).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }}
                          </v-col>
                          <v-col cols="2">
                            {{ category.percentage.toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ' %' }}
                          </v-col>
                          <v-col cols="1">
                            {{ category.count }}
                          </v-col>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item-group 
                      :multiple="false" 
                    >
                      <v-list-item disabled>
                        <template v-slot:default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title class="d-flex justify-space-between">
                              <v-col cols="1">

                              </v-col>
                              <v-col cols="6">
                                Nome
                              </v-col>
                              <v-col cols="2">
                                Totale
                              </v-col>
                              <v-col cols="2">
                                %
                              </v-col>
                              <v-col cols="1">
                                Qtà
                              </v-col>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                      <v-list-item v-for="item in category.services" :key="item.id">
                        <template v-slot:default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title class="d-flex justify-space-between">
                              <v-col cols="1">

                              </v-col>
                              <v-col cols="6">
                                {{item.objectName}}
                              </v-col>
                              <v-col cols="2">
                                {{Number(item[totalKey]).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}
                              </v-col>
                              <v-col cols="2">
                                <span v-if="Number(category.total) == 0">0,00 %</span>
                                <span v-else>{{(Number(item[totalKey]) / Number(category.total) * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ' %'}}</span>
                              </v-col>
                              <v-col cols="1">
                                {{item[quantityKey]}}
                              </v-col>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list-group>
                </v-list>
              </v-row>
            </v-col>
            <v-col
              lg="6"
              xl="6"
              md="12" 
              cols="12"
              sm="12"
            >
              <v-row>
                <GroupBasedChart
                  style="width: 100%; height: 100%"
                  type="bar"
                  transparent
                  :colors="['rgba(138,119,226,0.5)', 'rgba(138,119,226,0.5)', 'rgba(138,119,226,0.5)', 'rgba(138,119,226,0.5)', 'rgba(138,119,226,0.5)']"
                  :point-colors="['rgba(138,119,226,1)', 'rgba(138,119,226,1)', 'rgba(138,119,226,1)', 'rgba(138,119,226,1)', 'rgba(138,119,226,1)']"
                  :data="groupedTagItemsChartData"
                  :options="[]"
                  :getYValue="
                    (object) => {
                      return object[`count`];
                    }"
                  :getXValue="
                    (object) => {
                      return object[`name`];
                    }"
                  :x-axes-modifier="xAxes"
                  :tooltip-label-modifier="
                    (tooltipLabel) => {
                      return 'Quantità: ' + tooltipLabel.yLabel;
                    }"
                >
                </GroupBasedChart>
              </v-row>
              <v-row>
                <v-list style="width: 100%" color="transparent">
                  <v-list-item disabled>
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title class="d-flex justify-space-between">
                          <v-col cols="6">
                            Nome
                          </v-col>
                          <v-col cols="2">
                            Totale
                          </v-col>
                          <v-col cols="2">
                            %
                          </v-col>
                          <v-col cols="1">
                            Qtà
                          </v-col>
                          <v-col cols="1">
                            
                          </v-col>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                  <v-list-group
                    v-for="(category, idx) in groupedTagItems"
                    :key="idx"
                    no-action
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title class="d-flex justify-space-between">
                          <v-col cols="7">
                            {{ (idx + 1) + '° ' + category.name }}
                          </v-col>
                          <v-col cols="2">
                            {{ Number(category.total).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }}
                          </v-col>
                          <v-col cols="2">
                            {{ category.percentage.toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ' %' }}
                          </v-col>
                          <v-col cols="1">
                            {{ category.count }}
                          </v-col>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item-group 
                      :multiple="false" 
                    >
                      <v-list-item class="pl-12" disabled>
                        <template v-slot:default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title class="d-flex justify-space-between">
                              <v-col cols="1">

                              </v-col>
                              <v-col cols="6">
                                Nome
                              </v-col>
                              <v-col cols="2">
                                Totale
                              </v-col>
                              <v-col cols="2">
                                %
                              </v-col>
                              <v-col cols="1">
                                Qtà
                              </v-col>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                      <v-list-item class="pl-12" v-for="item in category.items" :key="item.id">
                        <template v-slot:default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title class="d-flex justify-space-between">
                              <v-col cols="1">

                              </v-col>
                              <v-col cols="6">
                                {{item.objectName}}
                              </v-col>
                              <v-col cols="2">
                                {{Number(item[totalKey]).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}
                              </v-col>
                              <v-col cols="2">
                                <span v-if="Number(category.total) == 0">0,00 %</span>
                                <span v-else>{{(Number(item[totalKey]) / Number(category.total) * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ' %'}}</span>
                              </v-col>
                              <v-col cols="1">
                                {{item[quantityKey]}}
                              </v-col>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list-group>
                </v-list>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <StandardDialog
      v-model="dialogBarcode"
      title="Area Riservata Sviluppatori" 
      :dialog-height="null"
      dialog-max-width="500px"
    >
      <ManualBarcodeInput
        v-model="operatorBarcode"
        scan-your-barcode-message="Immettere Codice"
        @confirm="activeBarcode"
      >
      </ManualBarcodeInput>
    </StandardDialog>
  </div>
</template>

<script>
import balanceService from '@/services/balance/balance.service.js'
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import FlowyGraph from "@/components/areas/analytics/FlowyGraph.vue";
import DynamicContentTable from '@/components/common/DynamicContentTable.vue';
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";
import analyticNamesMappings from '@/services/analytics/analyticsNameMappings'
import operatorService from '@/services/operators/operators.service.js'
import StandardDialog from '@/components/common/StandardDialog.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import PolarAreaChart from "@/components/charts/PolarAreaChart.vue";
import GroupBasedChart from '@/components/charts/GroupBasedChart.vue';
import GroupedSelectList from '@/components/common/GroupedSelectList.vue';
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import PieChart from "@/components/charts/PieChart";
import _ from "lodash";
import html2canvas from '@/assets/js/html2canvas.min.js'
import * as XLSX from "xlsx";

export default {
  name: "OperatorsBalance",
  components: {
    RangeDateSelector,
    TitleWithBackButton,
    FlowyGraph,
    OperatorsAutocomplete,
    DynamicContentTable,
    StandardDialog,
    ManualBarcodeInput,
    PolarAreaChart,
    GroupBasedChart,
    TypeDataTable,
    GroupedSelectList,
    PieChart
  },
  data: function() {
    let startOfMonth = new Date()
    startOfMonth.setDate(1)
    let endOfMonth = new Date()
    endOfMonth.setMonth(endOfMonth.getMonth() + 1)
    endOfMonth.setDate(0)

    return {
      results: undefined,
      startDate: startOfMonth,
      endDate: endOfMonth,
      loading: false,
      operator: undefined,
      treeOrDetailIndex: 0,
      isB: false,
      dialogBarcode: false,
      operatorBarcode: undefined,
      totalsData: [],
      serviceTypesCols: [],
      operatorTotalChartData: {},
      operatorTotalChartOptions: {
        scale: {
          pointLabels: {
            display: false,
            centerPointLabels: true,
            fontSize: 14,
            centerPointLabels: true,
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (val) => {
              return Number(val.value).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) + ' | ' 
              + (Number(Number(val.value) / Number(this.results.total[0].total)) * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ' %' 
            },
          },
        }
      },
      polarAreaColorSet: ['rgba(80,191,225,0.5)', 'rgba(138,119,226,0.5)'],
      operatorTotalRows: [],
      operatorTotalHeaders: [
        { text: '', value: 'color', type: 'color'},
        { text: '', value: 'name' },
        { text: 'Totale', value: 'total', type: 'price' },
        { text: '% tot', value: 'percentage' },
        { text: 'N° servizi/prodotti', value: 'count' },
        { text: 'Spesa media', value: 'average', type: 'price' },
        { text: 'N° clienti serviti', value: 'customersCount' },
      ],
      groupedTagServices: [],
      groupedTagServicesChartData: {},
      groupedTagItems: [],
      groupedTagItemsChartData: {},
      xAxes: [{
        ticks: {
          callback: function(label) {
            if(label.length > 18)
              return label.slice(0, 18) + '...';
            else
              return label;
          }
        },
      }],
      targetServices: [],
      hoursChartData: {},
      hoursChartOptions: {
        mainAspectRatio: true,
        responsive: true,
        legend: {
          display: true
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (val, val2) => {
              let minutes = Number(val2.datasets[val.datasetIndex].data[val.index])
              let hours = Math.floor(minutes / 60)
              minutes = Math.floor(minutes % 60)
              return val2.labels[val.index] + ': ' + hours + 'h ' + minutes + 'm'
            },
          },
        }
      },
      presenceChartData: {},
      presenceColorSet: ['#50BFE19c', '#52A8E09c', '#6C8FD49c', '#8873BC9c', '#9B55979c', '#A137699c'],
      presenceChartOptions: {
        mainAspectRatio: true,
        responsive: true,
        legend: {
          display: true
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (val, val2) => {
              let minutes = Number(val2.datasets[val.datasetIndex].data[val.index])
              let hours = Math.floor(minutes / 60)
              minutes = Math.floor(minutes % 60)
              return val2.labels[val.index] + ': ' + hours + 'h ' + minutes + 'm'
            },
          },
        }
      },
      delayChartData: {},
      delayChartOptions: {
        mainAspectRatio: true,
        responsive: true,
        legend: {
          display: true
        },
        tooltips: {
          enabled: true
        }
      },
      overtimeChartData: {},
      hoursTableHeaders: [
        { text: '', value: 'color', type: 'color'},
        { text: '', value: 'name' },
        { text: 'Ore', value: 'total' },
        { text: '% tot', value: 'percentage' },
      ],
      hoursTableRows: [],
      presenceTableHeaders: [
        { text: '', value: 'color', type: 'color'},
        { text: '', value: 'name' },
        { text: 'Ore', value: 'total' },
        { text: '% tot', value: 'percentage' },
      ],
      presenceTableRows: [],
      delayTableHeaders: [
        { text: '', value: 'color', type: 'color'},
        { text: '', value: 'name' },
        { text: 'Giorni', value: 'total' },
        { text: '% tot', value: 'percentage' },
      ],
      delayTableRows: [],
      overtimeTableHeaders: [
        { text: '', value: 'color', type: 'color'},
        { text: '', value: 'name' },
        { text: 'Ore', value: 'total' },
        { text: '% tot', value: 'percentage' },
      ],
      overtimeTableRows: [],
      fileName: "",
      level2TotalDatas: []
    }
  },
  mounted: function() {
    this.calculateOperatorTotal()
  },
  methods: {
    async exportToExcel() {
      // Export the tables to excel
      
      let workbook = XLSX.utils.book_new();
      let levelTotalDatas = []
      this.level2TotalDatas = []
      for (let i = 0; i < this.totalsData.length; i++) {
        this.totalsData[i].total = (Number(this.totalsData[i].total  || 0)).toFixed(2)
        this.totalsData[i].totalServices = (Number(this.totalsData[i].totalServices  || 0)).toFixed(2)
        levelTotalDatas.push(this.totalsData[i]);           
      }
      var ws = XLSX.utils.json_to_sheet(levelTotalDatas.map((element) => {
        let object = {}
        object["Operatore"] = element.name
        object["€ Tot"] = element.total
        object["% su € Tot"] = element.onTotal
        object["€ Servizi"] = element.totalServices
        object["N° Servizi"] = element.countServices
        object["€ Prodotti"] = element.totalItems
        object["N° Prodotti"] = element.countItems
        object["N° Clienti"] = element.countCustomers
        object["Di cui Acq."] = element.countItemCustomers
        object["% di Acq."] = element.onItemCustomers
        for (let cols of this.serviceTypesCols) {
          object[cols.text] = element[cols.value]
        }
        return object
      }));
      XLSX.utils.book_append_sheet(workbook, ws, "Riepilogo Commerciale Operatore");
      let datasArray = [],
      newServicesItemsDatas = [],
      newServicesDatas = [],
      newItemsDatas = []
      // SI INIZIA DA 1 PERCHÈ LA PRIMA VOCE È TOTALE GENERALE
      for (let i = 1; i < levelTotalDatas.length; i++) {
        this.operator = {id: levelTotalDatas[i].id, name: levelTotalDatas[i].name}
        await this.calculate()
        for (let j = 0; j < this.operatorTotalRows.length; j++) {
          //Aggiunta del campo operatorName per poterlo filtrare nel dettaglio operatore
          this.operatorTotalRows[j].count = Number(this.operatorTotalRows[j].count || 0)
          this.operatorTotalRows[j].average = Number((Number(this.operatorTotalRows[j].average || 0)).toFixed(2))
          this.operatorTotalRows[j].operatorName = levelTotalDatas[i].name
          datasArray.push(this.operatorTotalRows[j])
        }
        if(!!this.groupedTagServices)
          for (let j = 0; j < this.groupedTagServices.length; j++) {
            //Aggiunta del campo operatorName per poterlo filtrare nel dettaglio operatore
            this.groupedTagServices[j].operatorName = levelTotalDatas[i].name
            newServicesDatas.push(this.groupedTagServices[j])
          }
        if(!!this.groupedTagItems)
          for (let j = 0; j < this.groupedTagItems.length; j++) {
            //Aggiunta del campo operatorName per poterlo filtrare nel dettaglio operatore
            this.groupedTagItems[j].operatorName = levelTotalDatas[i].name
            newItemsDatas.push(this.groupedTagItems[j])
          }
      }
      let hoursWorkAbsenses = []
      for (let i = 0; i < this.level2TotalDatas.length; i++) {

        let totalAbsences = 0
        if (Array.isArray(this.level2TotalDatas[i].absences) && this.level2TotalDatas[i].absences.length > 0) {
          for (let j = 0; j < this.level2TotalDatas[i].absences.length; j++) {
            totalAbsences += Number(this.level2TotalDatas[i].absences[j].minutes || 0)
          }
        }

        if (typeof(this.level2TotalDatas[i].absences) === 'object' && !Array.isArray(this.level2TotalDatas[i].absences)) {
          totalAbsences = Number(this.level2TotalDatas[i].absences.minutes || 0)
        }

        let operatorHoursStructure = {
          operatorName: levelTotalDatas[i+1].name,
          operatorWorkedHours: this.getHoursLabel(this.level2TotalDatas[i].workedHours.minutes),
          operatorAbsences: this.getHoursLabel(totalAbsences),
          operatorDelays: this.getHoursLabel(this.level2TotalDatas[i].loggedHours.delayLogs),
          operatorOvertime: !!this.level2TotalDatas[i].loggedHours.overtimeMinutes ? this.getHoursLabel(this.level2TotalDatas[i].loggedHours.overtimeMinutes) : this.getHoursLabel(0),
        }
        hoursWorkAbsenses.push(operatorHoursStructure)
      }
      var ws1 = XLSX.utils.json_to_sheet(hoursWorkAbsenses.map((element) => {
        return {
          "Operatore": element.operatorName,
          "Minuti lavorati": element.operatorWorkedHours,
          "Minuti assenze": element.operatorAbsences,
          "Minuti ritardi": element.operatorDelays,
          "Minuti straordinari": element.operatorOvertime,
        }
      }));
      XLSX.utils.book_append_sheet(workbook, ws1, "Ore lavorate-assenze");
      for (let i = 0; i < this.level2TotalDatas.length; i++) {
        let itemsList = [],
        servicesList = []
        
        newServicesItemsDatas = []
        
        let newDatasArray = datasArray.filter(element => 
          element.operatorName === levelTotalDatas[i+1].name
        )
        let newServicesDatasArray = newServicesDatas.filter(element => 
          element.operatorName === levelTotalDatas[i+1].name
        )
        let newItemsDatasArray = newItemsDatas.filter(element => 
          element.operatorName === levelTotalDatas[i+1].name
        )
        for (let j = 0; j < newServicesDatasArray.length; j++) {
          newServicesDatasArray[j].percentage = Number((Number(newServicesDatasArray[j].percentage || 0)).toFixed(2)) + "%"     
          newServicesItemsDatas.push(newServicesDatasArray[j])
        }
        for (let j = 0; j < newItemsDatasArray.length; j++) {
          newItemsDatasArray[j].percentage = Number((Number(newItemsDatasArray[j].percentage || 0)).toFixed(2)) + "%"  
          newServicesItemsDatas.push(newItemsDatasArray[j])          
        }
        // Aggiungiamo i dati riguardanti i servizi e i prodotti     
        if (newServicesDatasArray.length > 0)
          for (let j = 0; j < newServicesDatasArray.length; j++) {
            for (let k = 0; k < newServicesDatasArray[j].services.length; k++) {
              newServicesDatasArray[j].services[k].count = newServicesDatasArray[j].services[k].quantity
              newServicesDatasArray[j].services[k].total = Number((Number(newServicesDatasArray[j].services[k].totalPaid || 0)).toFixed(2))
              newServicesDatasArray[j].services[k].type = "Servizio"
              newServicesDatasArray[j].services[k].name = newServicesDatasArray[j].services[k].objectName
              newServicesDatasArray[j].services[k].serviceItemType = newServicesDatasArray[j].name
              servicesList.push(newServicesDatasArray[j].services[k])
            }          
          }
        if (newItemsDatasArray.length > 0)
          for (let j = 0; j < newItemsDatasArray.length; j++) {
            for (let k = 0; k < newItemsDatasArray[j].items.length; k++) {
              newItemsDatasArray[j].items[k].count = newItemsDatasArray[j].items[k].quantity
              newItemsDatasArray[j].items[k].total = Number((Number(newItemsDatasArray[j].items[k].totalPaid || 0)).toFixed(2))
              newItemsDatasArray[j].items[k].type = "Prodotto"
              newItemsDatasArray[j].items[k].name = newItemsDatasArray[j].items[k].objectName
              newItemsDatasArray[j].items[k].serviceItemType = newItemsDatasArray[j].name
              itemsList.push(newItemsDatasArray[j].items[k])
            }          
          }

        let ws2 = XLSX.utils.aoa_to_sheet([[]]);
        XLSX.utils.sheet_add_aoa(ws2, [
          [
            '',
            'Totale',
            '% Tot',
            'N° Servizi / Prodotti',
            'Spesa media',
            'N° Clienti serviti',
          ],
          ...newDatasArray.map((el) => {
            return [
              el.name,
              Number((Number(el.total || 0)).toFixed(2)),
              el.percentage,
              el.count,
              el.average,
              Number(el.customersCount || 0),
            ]
          }),
          [],
          [
            'Famiglie',
            'Totale',
            '% Tot',
            'N° Servizi / Prodotti',
          ],
          ...newServicesItemsDatas.map((el) => {
            return [
              el.name,
              el.total,
              el.percentage,
              el.count,
            ]
          }),
          [],
          [
            'Servizio',
            'Totale',
            'Quantità',
            'Tipologia',
            'Famiglia',
          ],
          ...servicesList.map((el) => {
            return [
              el.name,
              el.total,
              el.count,
              el.type,
              el.serviceItemType
            ]
          }),
          [],
          [
            'Prodotto',
            'Totale',
            'Quantità',
            'Tipologia',
            'Famiglia',
          ],
          ...itemsList.map((el) => {
            return [
              el.name,
              el.total,
              el.count,
              el.type,
              el.serviceItemType
            ]
          })
        ])
        XLSX.utils.book_append_sheet(workbook, ws2, levelTotalDatas[i+1].name);
      }
      XLSX.writeFile(workbook, this.fileName, { type: 'file' });
    },
    async calculate() {
      if (!this.operator) {
        return
      }

      this.loading = true
      const startDate = this.startDate,
      endDate = this.endDate
      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)

      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)

      let results = await balanceService.operatorByTags({
        from: startDate,
        to: endDate,
        operatorId: this.operator.id,
        ghost: this.isB
      })
      this.results = results
      this.level2TotalDatas.push(results)

      this.drawGraph()
      this.calculateHours()

      this.loading = false
    },
    handleClick(item) {
      setTimeout(() => {
        const id = "operators-table-container"
        const tableContainer = document.getElementById(id)
        const scrollTop = 100 + tableContainer.offsetHeight

        window.scrollTo({
          top: scrollTop,
          behavior: "smooth",
        })
      }, 200);

      if(!item.id){
        this.operator = undefined
        return
      }

      this.operator = { id: item.id, name: item.name }
      this.calculate()
    },
    drawGraph(){
      //#region Total
      let data = []
      this.operatorTotalRows = []
      let totalService = this.results.totalTagsResult.find(el => el.type == 'service')
      let totalItem = this.results.totalTagsResult.find(el => el.type == 'item')
      let totalTotal = this.results.totalTagsResult.find(el => el.type == 'totals')

      if(!totalService){
        totalService = {
          total: 0,
          totalWithB: 0,
          servicesNumber: 0,
          servicesNumberWithB: 0,
          customerNumber: 0,
          customerNumberWithB: 0,
        }
      }
      if(!totalItem){
        totalItem = {
          total: 0,
          totalWithB: 0,
          itemsNumber: 0,
          itemsNumberWithB: 0,
          customerNumber: 0,
          customerNumberWithB: 0,
        }
      }
      if(!totalTotal){
        totalTotal = {
          total: 0,
          totalWithB: 0,
          customerNumber: 0,
          customerNumberWithB: 0,
          servicesNumber: 0,
          servicesNumberWithB: 0,
          itemsNumber: 0,
          itemsNumberWithB: 0,
        }
      }

      if(this.isB){
        totalService.total = totalService.totalWithB
        totalService.servicesNumber = totalService.servicesNumberWithB
        totalService.customerNumber = totalService.customerNumberWithB
        totalItem.total = totalItem.totalWithB
        totalItem.itemsNumber = totalItem.itemsNumberWithB
        totalItem.customerNumber = totalItem.customerNumberWithB
        totalTotal.customerNumber = totalTotal.customerNumberWithB
        totalTotal.total = totalTotal.totalWithB
        totalTotal.servicesNumber = totalTotal.servicesNumberWithB
        totalTotal.itemsNumber = totalTotal.itemsNumberWithB
      }

      let totalObject = {
        color: undefined,
        name: 'Totale',
        total: totalTotal.total,
        percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
        count: Number(totalTotal.servicesNumber || 0) + Number(totalTotal.itemsNumber || 0),
        average: (Number(totalTotal.total || 0) / Number(totalTotal.customerNumber || 1)),
        customersCount: totalTotal.customerNumber,
      }

      this.operatorTotalRows.push(totalObject)
      if(!!this.availableTags.service) {
        data.push(totalService.total)
        this.operatorTotalRows.push({
          color: this.polarAreaColorSet[0],
          name: 'Servizi',
          total: Number(totalService.total || 0),
          percentage: (!!Number(totalObject.total) ? Number(Number(totalService.total || 0) / Number(totalObject.total || 1)) * 100 : 0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          count: totalService.servicesNumber,
          average: (Number(totalService.total || 0) / Number(totalService.customerNumber || 1)),
          customersCount: totalService.customerNumber,
        })
      }
      else
        data.push(0)

      if(!!this.availableTags.item) {
        data.push(totalItem.total)
        this.operatorTotalRows.push({
          color: this.polarAreaColorSet[1],
          name: 'Prodotti',
          total: Number(totalItem.total || 0),
          percentage: (!!Number(totalObject.total) ? Number(totalItem.total / Number(totalObject.total || 1)) * 100 : 0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          count: totalItem.itemsNumber,
          average: (Number(totalItem.total || 0) / Number(totalItem.customerNumber || 1)),
          customersCount: totalItem.customerNumber,
        })
      }
      else
        data.push(0)

      
      let chartData = {
        labels: ['Servizi', 'Prodotti'],
        datasets: [{
          data: data,
          backgroundColor: this.polarAreaColorSet,
        }]
      }

      this.operatorTotalChartData = chartData
      //#endregion

      //#region Grouped services by tag
      let services = _.groupBy(this.results.services, (service) => { return !!service.tags ? service.tags[0].name : 'noTag' })
      let groupedTagServices = []
      for(let group of Object.values(services)){
        let serviceForGroup = group.map(service=>{
          return this.results.details.find((ser => ser.type == 'service' && ser.objectId == (service.id + '_service')))
        }).sort((a, b) => { return Number(b[this.quantityKey] || 0) - Number(a[this.quantityKey] || 0) }).filter(item => Number(item[this.quantityKey] || 0) > 0)

        if(serviceForGroup.length > 0)
          groupedTagServices.push({
            name: !!group[0].tags ? group[0].tags[0].description : 'Nessuna categoria',
            count: _.sumBy(group, (service) => { 
              return Number(this.results.details.find((ser => ser.type == 'service' && ser.objectId == (service.id + '_service')))[this.quantityKey] || 0) 
            }),
            services: serviceForGroup,
            total: _.sumBy(group, (service) => { 
              return Number(this.results.details.find((ser => ser.type == 'service' && ser.objectId == (service.id + '_service')))[this.totalKey] || 0) 
            }),
            tagCategory: !!group[0].tags ? group[0].tags[0].tagCategoryName : 'Nessuna categoria',
            percentage: (Number(_.sumBy(group, (service) => { 
              return Number(this.results.details.find((ser => ser.type == 'service' && ser.objectId == (service.id + '_service')))[this.totalKey] || 0) 
            }) / Number(!!this.availableTags && !!this.availableTags.service ? (this.availableTags.service.total || 1) : 1)) * 100),
          })
      }
      this.groupedTagServices = groupedTagServices
      this.groupedTagServicesChartData = [{
        name: "Servizi più venduti",
        label: "Servizi più venduti",
        data: groupedTagServices.sort((a, b) => { return b.count - a.count }).slice(0, 5),
      }]

      //#endregion

      //#region Grouped items by tag
      let items = _.groupBy(this.results.items, (item) => { return !!item.tags ? item.tags[0].name : 'noTag' })
      let groupedTagItems = []
      for(let group of Object.values(items)){
        let itemForGroup = group.map(item=>{
          return this.results.details.find((ite => ite.type == 'item' && ite.objectId == (item.id + '_item')))
        }).sort((a, b) => { return Number(b[this.quantityKey] || 0) - Number(a[this.quantityKey] || 0) }).filter(item => Number(item[this.quantityKey] || 0) > 0)

        if(itemForGroup.length > 0)
          groupedTagItems.push({
            name: !!group[0].tags ? group[0].tags[0].description : 'Nessuna categoria',
            count: _.sumBy(group, (item) => { return Number(this.results.details.find((it => it.type == 'item' && it.objectId == (item.id + '_item')))[this.quantityKey] || 0) }),
            items: itemForGroup,
            total: _.sumBy(group, (item) => { 
              return Number(this.results.details.find((it => it.type == 'item' && it.objectId == (item.id + '_item')))[this.totalKey] || 0) 
            }),
            tagCategory: !!group[0].tags ? group[0].tags[0].tagCategoryName : 'Nessuna categoria',
            percentage: (Number(_.sumBy(group, (item) => { 
              return Number(this.results.details.find((it => it.type == 'item' && it.objectId == (item.id + '_item')))[this.totalKey] || 0) 
            }) / Number(!!this.availableTags && !!this.availableTags.item ? (this.availableTags.item.total || 1) : 1)) * 100),
          })
      }
      this.groupedTagItems = groupedTagItems
      this.groupedTagItemsChartData = [{
        name: "Prodotti più venduti",
        label: "Prodotti più venduti",
        data: groupedTagItems.sort((a, b) => { return b.count - a.count }).slice(0, 5),
      }]

      //#endregion
    },
    defaultObject() {
      return { 
        total: 0,
        customerNumber: 0,
        number: 0,
      }
    },
    changeWithB() {      
      this.isB = !this.isB
      this.calculateOperatorTotal()
    },
    activeBarcode(){
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then((result) => {
        if (result) {
          this.changeWithB()
        } else {
          this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
        }
      }).catch(() => {
        this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
    getHoursLabel(minutes){
      minutes = Number(minutes)
      let hours = Math.floor(minutes / 60)
      minutes = Math.floor(minutes % 60)
      return hours + 'h ' + minutes + 'm'
    },
    calculateHours(){
      let results = this.results

      let loggedHours = Number(results.loggedHours.minutes)
      let workedHours = Number(results.workedHours.minutes)
      this.hoursChartData = {
        labels: ['Ore lavorate', 'Ore registrate'],
        datasets: [{
          label: 'Label',
          pointBackgroundColor: 'black',
          borderWidth: 2,
          pointBorderColor: 'black',
          data: [workedHours, loggedHours],
          backgroundColor: this.polarAreaColorSet,
        }]
      }
      let total = workedHours + loggedHours
      total = total || 1
      this.hoursTableRows = [
        {
          color: this.polarAreaColorSet[0],
          name: 'Ore lavorate',
          total: this.getHoursLabel(workedHours),
          percentage: (workedHours / total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
        },
        {
          color: this.polarAreaColorSet[1],
          name: 'Ore registrate',
          total: this.getHoursLabel(loggedHours),
          percentage: (loggedHours / total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
        },
      ]

      let absences = results.absences
      this.presenceChartData = {
        labels: absences.length ? absences.map(absence => absence.absenceTypeName) : ['Assente'],
        datasets: [
          {
            label: 'Label',
            pointBackgroundColor: 'black',
            borderWidth: 2,
            pointBorderColor: 'black',
            data: absences.map(absence => Number(absence.minutes)),
            backgroundColor: absences.map((absence, index) => this.presenceColorSet[index]),
          }
        ]
      }
      total = absences.reduce((total, absence) => { return total + Number(absence.minutes) }, 0) || 1
      this.presenceTableRows = absences.map((absence, index) => {
        return {
          color: this.presenceColorSet[index],
          name: absence.absenceTypeName,
          total: this.getHoursLabel(absence.minutes),
          percentage: (Number(absence.minutes) / total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
        }
      })

      this.delayChartData = {
        labels: ['In orario', 'In ritardo'],
        datasets: [
          {
            label: 'Label',
            pointBackgroundColor: 'black',
            borderWidth: 2,
            pointBorderColor: 'black',
            data: [Number(results.loggedHours.ordinaryLogs), Number(results.loggedHours.delayLogs)],
            backgroundColor: [this.presenceColorSet[0], this.presenceColorSet[this.presenceColorSet.length - 1]],
          }
        ]
      }
      total = Number(results.loggedHours.ordinaryLogs) + Number(results.loggedHours.delayLogs)
      total = total || 1
      this.delayTableRows = [
        {
          color: this.presenceColorSet[0],
          name: 'In orario',
          total: results.loggedHours.ordinaryLogs,
          percentage: (Number(results.loggedHours.ordinaryLogs) / total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
        },
        {
          color: this.presenceColorSet[this.presenceColorSet.length - 1],
          name: 'In ritardo',
          total: results.loggedHours.delayLogs,
          percentage: (Number(results.loggedHours.delayLogs) / total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
        },
      ]

      this.overtimeChartData = {
        labels: ['Ore ordinarie', 'Ore straordinarie'],
        datasets: [{
          label: 'Label',
          pointBackgroundColor: 'black',
          borderWidth: 2,
          pointBorderColor: 'black',
          data: [Math.max(loggedHours - Number(results.loggedHours.overtimeMinutes), 0), Number(results.loggedHours.overtimeMinutes)],
          backgroundColor: this.polarAreaColorSet,
        }]
      }
      total = loggedHours || 1
      this.overtimeTableRows = [
        {
          color: this.polarAreaColorSet[0],
          name: 'Ore ordinarie',
          total: this.getHoursLabel(Math.max(loggedHours - Number(results.loggedHours.overtimeMinutes), 0)),
          percentage: (Math.max(loggedHours - Number(results.loggedHours.overtimeMinutes), 0) / total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
        },
        {
          color: this.polarAreaColorSet[1],
          name: 'Ore straordinarie',
          total: this.getHoursLabel(Number(results.loggedHours.overtimeMinutes)),
          percentage: (Number(results.loggedHours.overtimeMinutes) / total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
        },
      ]
    },
    async calculateOperatorTotal(){
      this.loading = true
      const startDate = this.startDate,
      endDate = this.endDate
      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)

      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)

      this.fileName = "Riepilogo-Commerciale-Operatore_da_" + startDate.toLocaleDateString('it-IT') + "_a_" + endDate.toLocaleDateString('it-IT') + ".xls";

      let res = await balanceService.totalOperators({
        from: startDate,
        to: endDate,
        ghost: this.isB,
      })

      this.serviceTypesCols = []
      for(let serviceType of res.serviceTypeToUse) {
        this.serviceTypesCols.push({
          text: serviceType.name + ' su €',
          value: serviceType.id + 'onTotal',
          type: 'custom-type', 
          customType: 'customTypeText'
        })

        this.serviceTypesCols.push({
          text: serviceType.name + ' su Servizi',
          value: serviceType.id + 'onService',
          type: 'custom-type', 
          customType: 'customTypeText'
        })

        this.serviceTypesCols.push({
          text: serviceType.name + ' su Transazioni',
          value: serviceType.id + 'onTransaction',
          type: 'custom-type', 
          customType: 'customTypeText'
        })
      }

      this.totalsData = res.operatorTotals.map((row) => {
        let obj = {
          id: row.operatorId,
          name: row.operatorName,
          total: row.total,
          onTotal: this.calculatePercentValues(res.total.total, row.total) + '%',
          totalServices: !!row.serviceTotal ? row.serviceTotal : 0,
          countServices: row.serviceQuantity,
          totalItems: row.itemTotal,
          countItems: !!row.itemQuantity ? row.itemQuantity : 0,
          countCustomers: row.countCustomers,
          countItemCustomers: row.countItemCustomers,
          onItemCustomers: this.calculatePercentValues(row.countCustomers, row.countItemCustomers) + '%',
        }

        let findedServiceType = res.serviceTypes.filter((serviceType) => { return serviceType.operatorId == row.operatorId })
        for(let serviceType of findedServiceType){
          obj[serviceType.serviceTypeId + 'onTotal'] = this.calculatePercentValues(row.total, serviceType.total) + '%'
          obj[serviceType.serviceTypeId + 'onService'] = this.calculatePercentValues(row.serviceQuantity, serviceType.quantity) + '%'
          obj[serviceType.serviceTypeId + 'onTransaction'] = this.calculatePercentValues(row.countTransactions, serviceType.countTransactions) + '%'
        }

        for(let col of this.serviceTypesCols){
          if(!obj[col.value]){
            obj[col.value] = 0 + '%'
          }
        }

        return obj
      })

      this.totalsData.unshift({
        name: 'Totale Generale',
        total: Number(res.total.total),
        onTotal: '100%',
        totalServices: res.total.serviceTotal,
        countServices: !!res.total.serviceQuantity ? res.total.serviceQuantity : 0,
        totalItems: res.total.itemTotal,
        countItems: !!res.total.itemQuantity ? res.total.itemQuantity : 0,
        countCustomers: res.total.countCustomers,
        countItemCustomers: res.total.countItemCustomers,
        onItemCustomers: this.calculatePercentValues(res.total.countCustomers, res.total.countItemCustomers) + '%',
      })

      for(let serviceType of res.serviceTypeTotal) {
        this.totalsData[0][serviceType.serviceTypeId + 'onTotal'] = this.calculatePercentValues(res.total.total, serviceType.total) + '%'
        this.totalsData[0][serviceType.serviceTypeId + 'onService'] = this.calculatePercentValues(res.total.serviceQuantity, serviceType.quantity) + '%'
        this.totalsData[0][serviceType.serviceTypeId + 'onTransaction'] = this.calculatePercentValues(res.total.countTransactions, serviceType.countTransactions) + '%'
      }

      for(let col of this.serviceTypesCols){
        if(!this.totalsData[0][col.value]){
          this.totalsData[0][col.value] = 0 + '%'
        }
      }

      this.loading = false
      this.calculate()
    },
    calculatePercentValues(total, part) {
      // x : 100 = part : total
      if(!Number(total)) return 0
      total = Number(Number(total).toFixed(2))
      part = Number(Number(part).toFixed(2))
      return Number(Number( (part * 100) / total ).toFixed(2))
    },
    print() {
      let id = 'chart'
      if(this.treeOrDetailIndex)
        id = 'table'
      this.printLoading = true

      let name = ""
      if(!!this.operator)
        name = "Riepilogo_Comerciale_" + this.operator.name 
          + "_" + this.startDate.getDate() + "-" + (this.startDate.getMonth() + 1) 
          + "_" + this.endDate.getDate() + "-" + (this.endDate.getMonth() + 1) +  ".png";
      else
        name = "Riepilogo_Comerciale_Operatori_" + this.startDate.getDate() + "-" + (this.startDate.getMonth() + 1) 
          + "_" + this.endDate.getDate() + "-" + (this.endDate.getMonth() + 1) +  ".png";

      html2canvas(document.getElementById(id), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = name
        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error)=>{
        console.error(error)
        this.printLoading = false
      });
    }
  },
  computed: {
    totalKey(){
      return ['totalPaid', 'totalPaidWithB'][Number(this.isB)]
    },
    quantityKey(){
      return ['quantity', 'quantityWithB'][Number(this.isB)]
    },
    total() {
      const total = !this.isB ? "total" : "totalWithB"
      if (!!this.results && !!this.results.total && Array.isArray(this.results.total) && this.results.total.length > 0 && 
        this.results.total[0][total] != null) {
        return this.results.total[0][total]
      } else {
        return 0
      }
    },
    totalDiscounts() {
      const discounts = !this.isB ? "discounts" : "discountsWithB"
      if (!!this.results && !!this.results.total && Array.isArray(this.results.total) && this.results.total.length > 0 && 
        this.results.total[0][discounts] != null) {
        return Number(Number(this.results.total[0][discounts]).toFixed(2))
      } else {
        return 0
      }
    },
    availableTags() {
      let totalTags = {}
      if(!!this.results & !!this.results.tags) {
        for (let i = 0; i < this.results.tags.length; i += 1) {
          const serviceOrItem = this.results.tags[i].type
          const paymentTransactionId = this.results.tags[i].paymentTransactionId

          const total = !this.isB ? "total" : "totalWithB"
          const customerNumber = !this.isB ? "customerNumber" : "customerNumberWithB"
          const itemsNumber = !this.isB ? "itemsNumber" : "itemsNumberWithB"
          const servicesNumber = !this.isB ? "servicesNumber" : "servicesNumberWithB"
          
          if (!serviceOrItem) continue
          if(!totalTags[serviceOrItem]) totalTags[serviceOrItem] = this.defaultObject() 

          totalTags[serviceOrItem].total = Number(Number( Number(Number(totalTags[serviceOrItem].total).toFixed(2)) + Number(Number(this.results.tags[i][total]).toFixed(2))).toFixed(2))
          
          totalTags[serviceOrItem].total = Number(totalTags[serviceOrItem].total.toFixed(2))

          if (i == 0 || (i != 0 && paymentTransactionId != this.results.tags[i - 1].paymentTransactionId) || this.results.tags[i - 1].type != serviceOrItem) {
            totalTags[serviceOrItem].customerNumber = Number(Number(totalTags[serviceOrItem].customerNumber + Number(Number(this.results.tags[i][customerNumber]).toFixed(2))).toFixed(2))
          }

          if (serviceOrItem == 'item') {
            totalTags[serviceOrItem].number = Number(Number(totalTags[serviceOrItem].number + Number(Number(this.results.tags[i][itemsNumber]).toFixed(2))).toFixed(2))
          }

          if (serviceOrItem == 'service') {
            totalTags[serviceOrItem].number = Number(Number(totalTags[serviceOrItem].number + Number(Number(this.results.tags[i][servicesNumber]).toFixed(2))).toFixed(2))
          }

          if(serviceOrItem == 'totals'){
            totalTags[serviceOrItem].number = Number(Number(totalTags[serviceOrItem].number + Number(Number(this.results.tags[i][servicesNumber]).toFixed(2))).toFixed(2))
          }
        }
        

        for (const [serviceOrItem, value] of Object.entries(totalTags)) {
          if(serviceOrItem == 'totals') continue
          totalTags[serviceOrItem].perc = this.calculatePercentValues((this.total), value.total).toLocaleString('it-IT')
          totalTags[serviceOrItem].number = totalTags[serviceOrItem].number//.toLocaleString('it-IT')
        }

        return totalTags
      }
    },
    itemsRows() {
      if(!this.results || this.results.length == 0 || !this.results.details || this.results.details.length == 0) {
        return []
      }

      return this.results.details.filter(el => {
        if(el.type == 'item'){
          if(this.isB)
            return true
          else
            return !el.quantity || Number(el.quantity) == 0 ? false : true
        } else
          return false
      })
    },
    servicesRows() {
      if(!this.results || this.results.length == 0 || !this.results.details || this.results.details.length == 0) {
        return []
      }

      return this.results.details.filter(el => {
        if(el.type == 'service'){
          if(this.isB)
            return true
          else
            return !el.quantity || Number(el.quantity) == 0 ? false : true
        } else
          return false
      })
    },
    totalTableHeaders() {
      return [
        { text: 'Operatore', value: 'name', type: 'custom', class: ['sticky-column-header', 'column-header-primary'], cellClass: ['sticky-column', 'column-primary'], overrideClass: true },
        { text: '€ Tot', value: 'total', type: 'custom-type', customType: 'customTypePrice' },
        { text: '% su € Tot', value: 'onTotal', type: 'custom-type', customType: 'customTypeText' },
        { text: '€ Servizi', value: 'totalServices', type: 'custom-type', customType: 'customTypePrice' },
        { text: 'N° Servizi', value: 'countServices', type: 'custom-type', customType: 'customTypeText' },
        { text: '€ Prodotti', value: 'totalItems', type: 'custom-type', customType: 'customTypePrice' },
        { text: 'N° Prodotti', value: 'countItems', type: 'custom-type', customType: 'customTypeText' },
        { text: 'N° Clienti', value: 'countCustomers', type: 'custom-type', customType: 'customTypeText' },
        { text: 'di cui Acq.', value: 'countItemCustomers', type: 'custom-type', customType: 'customTypeText' },
        { text: '% di Acq.', value: 'onItemCustomers', type: 'custom-type', customType: 'customTypeText' },
        ...this.serviceTypesCols
      ]
    },
    headers() {
      if(!this.results || this.results.length == 0) return []
      else if (!this.isB)
        return [
          {
            value: 'objectName',
            text: 'Nome',
            minWidth: "200px",
          },
          {
            value: 'quantity',
            text: 'Quantità',
            minWidth: '80px'
          },
          {
            value: 'averagePrice',
            text: 'Prezzo medio',
            minWidth: '80px'
          },
        ]
      else
        return [
          {
            value: 'objectName',
            text: 'Nome',
            minWidth: "200px",
          },
          {
            value: 'quantityWithB',
            text: 'Quantità',
            minWidth: '80px'
          },
          {
            value: 'averagePriceWithB',
            text: 'Prezzo medio',
            minWidth: '80px'
          },
        ]
    },
  }
}
</script>

<style scoped>
.table-gradient {
  box-shadow: 0px 0px 0px 0px;
}
</style>